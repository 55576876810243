import React, { useState } from "react";
import { Form, Input, Button, Typography, Alert, message } from "antd";
import { useUser } from "../../UserContext";

const { Title } = Typography;

const LOGIN_APP_URL = process.env.REACT_APP_LOGIN_API_URL;

const LoginPage = ({ onLogin }) => {
  const [error, setError] = useState("");
  const [loadingLogin, setLoadingLogin] = useState(false);
  const { setUser } = useUser();
  const [messageApi, contextHolder] = message.useMessage();

  const handleSubmit = async (values) => {
    const { username, password } = values;

    try {
      setLoadingLogin(true);
      const response = await fetch(LOGIN_APP_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.status === 200) {
        setUser({ username });
        setError("");
        onLogin(); // Aggiorna lo stato dell'applicazione per indicare che l'utente ha eseguito l'accesso
      } else {
        messageApi.open({
          type: "error",
          content: "Credenziali errate",
        });
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Errore durante il login",
      });
    } finally {
      setLoadingLogin(false);
    }
  };

  return (
    <div style={{ width: "300px", margin: "auto", paddingTop: "100px" }}>
      <Title level={2}>Login</Title>
      {error && <Alert message={error} type="error" />}
      {contextHolder}
      <Form name="login" onFinish={handleSubmit}>
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Inserisci il tuo username!",
            },
          ]}
        >
          <Input allowClear />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Inserisci la tua password!",
            },
          ]}
        >
          <Input.Password allowClear />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loadingLogin}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
