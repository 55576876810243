import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import LoginPage from "./pages/login/LoginPage";
import PersonalePage from "./pages/personale/PersonalePage";
import RepartiPage from "./pages/reparti/RepartiPage";
import TurniPage from "./pages/turni/TurniPage";
import StatistichePage from "./pages/statistiche/StatistichePage";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import { useUser } from "./UserContext";
import { LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Layout, Menu, Row, Col, Drawer } from "antd";

const { Header, Content } = Layout;

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const { user, setUser } = useUser();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleLogin = () => {
    setIsLoggedIn(true);
    navigate("/personale");
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUser(null);
    navigate("/");
  };

  if (!isLoggedIn) {
    return <LoginPage onLogin={handleLogin} />;
  }

  return (
    <Layout>
      <Header>
        <Row justify="space-between" align="middle">
          <Col span={20}>
            {!isMobile && (
              <Menu theme="dark" mode="horizontal">
                <Menu.Item key="1">
                  <Link to="/personale">Personale</Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/reparti">Reparti</Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to="/turni">Calendario Turni</Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link to="/statistiche">Statistiche</Link>
                </Menu.Item>
              </Menu>
            )}
          </Col>
          <Col span={4}>
            <Row justify="end" align="middle">
              <Col style={{ color: "white" }}>{user.username}</Col>
              <Col>
                <Button
                  style={{ color: "red", marginLeft: "15px" }}
                  type="text"
                  icon={<LogoutOutlined />}
                  onClick={handleLogout}
                />
              </Col>
              {isMobile && (
                <Col>
                  <Button
                    icon={<MenuOutlined />}
                    onClick={() => setDrawerVisible(true)}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        {isMobile && (
          <Drawer
            title="Menu"
            placement="left"
            closable={true}
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
          >
            <Menu theme="dark" mode="vertical">
              <Menu.Item key="1">
                <Link to="/personale">Personale</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to="/reparti">Reparti</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to="/turni">Calendario Turni</Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to="/statistiche">Statistiche</Link>
              </Menu.Item>
            </Menu>
          </Drawer>
        )}
      </Header>
      <Content style={{ padding: "50px" }}>
        <Routes>
          <Route path="/" element={<LoginPage onLogin={handleLogin} />} />
          <Route path="/personale" element={<PersonalePage />} />
          <Route path="/reparti" element={<RepartiPage />} />
          <Route path="/turni" element={<TurniPage />} />
          <Route path="/statistiche" element={<StatistichePage />} />
        </Routes>
      </Content>
    </Layout>
  );
}

export default App;
