import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  message,
  Tooltip,
  Space,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import "./personal-table.css";

const PERSONALE_API_URL = process.env.REACT_APP_PERSONALE_API_URL;

const PersonalTable = () => {
  const [personale, setPersonale] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPersonale, setSelectedPersonale] = useState(null);
  const [form] = Form.useForm();
  const [formChanged, setFormChanged] = useState(false);
  const [searchFilters, setSearchFilters] = useState({ cognome: "", nome: "" });

  // Carica il personale all'avvio del componente
  useEffect(() => {
    // fetchPersonale();
  }, []);

  const fetchPersonale = async (filters) => {
    setLoading(true);
    try {
      const response = await fetch(PERSONALE_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ action: "get_personale", filters }),
      });

      if (response.ok) {
        const data = await response.json();
        setPersonale(data);
      } else {
        console.log("Errore fetchPersonale");
      }
    } catch (error) {
      console.log("Errore connessione al DB fetchPersonale");
    } finally {
      setLoading(false);
    }
  };

  const addOrUpdatePersonale = async (personaleData) => {
    setLoading(true);
    try {
      const payload = {
        action: selectedPersonale ? "update_personale" : "add_personale",
        ...personaleData,
        id: selectedPersonale ? selectedPersonale.id : null,
      };

      // Aggiungi il campo password solo se è stato inserito un valore
      if (personaleData.password) {
        payload.password = personaleData.password;
      }

      const response = await fetch(PERSONALE_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok && !selectedPersonale.id) {
        setPersonale([...personale, personaleData]);
      } else if (response.ok && selectedPersonale.id) {
        setPersonale(
          personale.map((item) =>
            item.id === personaleData.id ? personaleData : item
          )
        );
      } else {
        message.error(
          "Errore durante l'aggiunta o l'aggiornamento del personale"
        );
      }
    } catch (error) {
      message.error("Errore di rete");
    } finally {
      setLoading(false);
      setModalVisible(false);
    }
  };

  const deletePersonale = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(PERSONALE_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "delete_personale",
          id,
        }),
      });

      if (response.ok) {
        setPersonale(personale.filter((item) => item.id !== id));
      } else {
        message.error("Errore durante l'eliminazione del personale");
      }
    } catch (error) {
      message.error("Errore di rete");
    } finally {
      setLoading(false);
    }
  };

  const showDeleteConfirm = (key) => {
    Modal.confirm({
      title: "Sei sicuro di voler eliminare?",
      content: "Questa azione non può essere annullata.",
      okText: "Sì",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deletePersonale(key);
      },
    });
  };

  const columns = [
    {
      title: "Cognome",
      dataIndex: "personale_cognome",
    },
    {
      title: "Nome",
      dataIndex: "personale_nome",
    },
    {
      title: "Azioni",
      dataIndex: "azioni",
      key: "azioni",
      align: "right",
      render: (_, record) => (
        <Space>
          <Tooltip title="Modifica">
            <Button
              onClick={() => showModal(record)}
              icon={<EditOutlined />}
              type="primary"
              ghost
            />
          </Tooltip>
          <Tooltip title="Elimina">
            <Button
              onClick={() => showDeleteConfirm(record.id)}
              icon={<DeleteOutlined />}
              type="primary"
              danger
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const showModal = (record = null) => {
    setSelectedPersonale(record);
    form.setFieldsValue(
      record
        ? {
            cognome: record.personale_cognome,
            nome: record.personale_nome,
            username: record.personale_username,
          }
        : {
            cognome: "",
            nome: "",
            username: "",
            password: "",
          }
    );
    setModalVisible(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setSelectedPersonale(null);
    setModalVisible(false);
    setFormChanged(false);
  };

  const handleSubmit = (values) => {
    const { confirmPassword, ...personaleData } = values;
    addOrUpdatePersonale(personaleData);
    form.resetFields();
    setFormChanged(false);
  };

  const handleSearch = () => {
    fetchPersonale(searchFilters);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <Form layout="inline">
          <Form.Item label="Cognome">
            <Input
              value={searchFilters.cognome}
              onChange={(e) =>
                setSearchFilters({ ...searchFilters, cognome: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Nome">
            <Input
              value={searchFilters.nome}
              onChange={(e) =>
                setSearchFilters({ ...searchFilters, nome: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item>
            <Button onClick={handleSearch} type="primary">
              Cerca
            </Button>
          </Form.Item>
        </Form>
        <Tooltip title="Aggiungi personale">
          <Button
            onClick={() => showModal()}
            type="primary"
            icon={<PlusOutlined />}
          />
        </Tooltip>
      </div>
      <Table
        rowKey="personale_id"
        columns={columns}
        dataSource={personale}
        loading={loading}
        pagination={{ pageSize: 10 }}
      />
      <Modal
        title={selectedPersonale ? "Modifica personale" : "Aggiungi personale"}
        open={modalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Annulla
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            disabled={!formChanged}
          >
            Salva
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{ nome: "", cognome: "", username: "", password: "" }}
          onValuesChange={() => setFormChanged(true)}
        >
          <Form.Item
            label="Cognome"
            name="cognome"
            rules={[
              { required: true, message: "Inserisci il cognome del personale" },
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item label="Nome" name="nome">
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Inserisci il tuo username!",
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Inserisci la tua password!",
              },
            ]}
          >
            <Input.Password allowClear />
          </Form.Item>
          <Form.Item
            label="Conferma password"
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Conferma la tua password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Le due password non corrispondono!")
                  );
                },
              }),
            ]}
          >
            <Input.Password allowClear />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PersonalTable;
