import React from "react";
import PersonalTable from "./PersonalTable";

const PersonalePage = () => {
  return (
    <div>
      <h1>Personale</h1>
      <PersonalTable />
    </div>
  );
};

export default PersonalePage;
